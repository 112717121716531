import React, { useState, useEffect } from 'react';
import CustomSelect from '../customSelect/CustomSelect';
import CustomMultiSelect from '../customMultiSelect/CustomMultiSelect';
import { userRoles } from '../../data/data';
import { createUser, updateUser, sendPasswordReset, getSchools, deleteUser } from '../../services/scpApi';
import bin from '../../assets/bin.svg';
import SaveButton from '../saveButton/SaveButton';


const CrudUserSingle = ({user, setUser, setView, loading, setLoading, refreshSelectedUser}) => {

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        userRole: ''
    });
    const [validationErrors, setValidationErrors] = useState([]);
    const [schools, setSchools] = useState([]);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

    const [selectedSchools, setSelectedSchools] = useState([]);

    // Get schools for select
    useEffect(() => {
        (async () => {
            try {
                const schoolsData = await getSchools();
                setSchools(schoolsData);
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
        })();
    }, []);

    // prepare form data for editing existing user
    useEffect(() => {
        // set form data for updating user
        if (user && user.id) {
            const newFormData = {
                email: user.email,
                name: user.name,
                userRole: user.user_role
            };

            setFormData(newFormData);

            // set selected schools
            setSelectedSchools(user.schools);
        }
        
        // set back to default if no user is selected
        else {        
            setFormData({
                email: ''
            });

            setSelectedSchools([]);
        }

        setLoading(null);
    }, [user]);

    // handle form data changes
    const handleChange = (e) => {
        // unset validation errors
        setValidationErrors([]);

        // handle form data change
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        // reset school if user role is changed
        if (name === 'userRole') {
            setFormData(prevData => ({
                ...prevData,
                school: []
            }));
        }
    };

    // handle update selected schools
    const updateSelectedSchools = (school) => {
        if (!school) {
            setSelectedSchools([]);
        }

        let newSelectedSChools = [...selectedSchools];

        if (newSelectedSChools.find((s) => s.id === school.id)) {
            // remove period from selected periods
            newSelectedSChools = newSelectedSChools.filter((s) => s.id !== school.id);
        }
        else {
            // add period to selected periods
            newSelectedSChools.push(school);
        }

        setSelectedSchools(newSelectedSChools);
    }

    // handle delete user
    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                setLoading('loading');

                await deleteUser(user.id);

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                }, 3000);

                refreshSelectedUser();  
                setView('list');  

            } catch (error) {

                setLoading('error');
                console.error('Error deleting user:', error);
            }
        } else {
            console.log('Deletion canceled');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidationErrors([]);  // Clear any previous validation errors
    
        // Set loading state to 'loading'
        setLoading('loading');

        const selectedSchoolIds = selectedSchools.map(school => school.id);
    
        try {
            if (user) {
                // Editing an existing user
                await updateUser({
                    id: user.id,
                    email: formData.email,
                    name: formData.name,
                    userRole: formData.userRole,
                    schools: selectedSchoolIds
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null);
                    refreshSelectedUser();
                }, 3000);
    
            } else {
                // Creating a new user
                const user = await createUser({
                    email: formData.email,
                    name: formData.name,
                    userRole: formData.userRole,
                    schools: selectedSchoolIds
                });

                setLoading('success');
                setTimeout(() => {
                    setLoading(null); 
                    //refreshSelectedUser(); 
                    setUser(user);
                }, 3000);
            }
        } catch (error) {
            // Collect server-side validation errors, if any
            const errors = [];
    
            if (error.response?.data?.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    errors.push(error.response.data.errors[key]);
                });
            } else if (error.response?.data?.message) {
                errors.push(error.response.data.message);
            }
    
            setValidationErrors(errors);  // Set validation errors to be displayed
            console.error('Error saving user:', error);

            setLoading('error'); 
            setTimeout(() => {
                setLoading(null);  
            }, 3000);
        }
    };
    

    const handlePasswordReset = async () => {
        if (!user || !user.id) {
            return;
        }

        setPasswordResetSuccess(true);  // Show success message
        setTimeout(() => {
            setPasswordResetSuccess(false);  // Hide it after 3 seconds
        }, 3000);
        
        try {
            await sendPasswordReset({
                userId: user.id
            });
        } catch (error) {
            const errors = [];

            if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    errors.push(error.response.data.errors[key]);
                });
            } else if (error.response.data.message && error.response.data.error) {
                errors.push(error.response.data.message);
                errors.push(error.response.data.error);
            }

            setValidationErrors(errors);
            console.error('Error sending password reset:', error);
        }
    }

    const routeKey = 'manage-users';
    const handleMenuBtnClick = () => setView({ isBackToList: true });

    // add event listener to sidebar buttons to change view
    useEffect(() => {
        const sidebarButtons = document.querySelectorAll('.sidebar a[href^="/portal/' + routeKey + '"]');
        sidebarButtons.forEach(button => {
            // if it doesn't already have an event listener
            if (!button.hasEventListener) {
                button.addEventListener('click', handleMenuBtnClick);
            }
        })

        // Cleanup to prevent memory leaks and double bindings
        return () => {
            sidebarButtons.forEach(button => {
                button.removeEventListener('click', handleMenuBtnClick);
            });
        };
    }, []);

    return (
        <div className="single-edit">
            <h2>{user ? 'Edit User' : 'Add New User'}</h2>
            <img className='bin-icon' src={bin} alt="rubbish bin icon" onClick={handleDelete}/>
            <form onSubmit={handleSubmit}>
                <label>Email:</label>
                <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />

                <label>Name:</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />

                <label>User Role:</label>
                <CustomSelect 
                    name="select-user-role" 
                    placeholder="Select User Role"
                    options={userRoles}
                    labelKey="name"
                    setSelectedOption={(option) => handleChange({ target: { name: 'userRole', value: option.role } })}
                    selectedOption={userRoles.find(userRole => userRole.role === formData.userRole)}
                    required
                />

                {formData.userRole && formData.userRole !== 'super_admin' &&
                    <>
                        <label>School:</label>
                        {/* <CustomSelect 
                            name="select-school" 
                            placeholder="Select School"
                            options={schools}
                            labelKey="name"
                            setSelectedOption={(option) => handleChange({ target: { name: 'school', value: option.id } })}
                            selectedOption={schools.find(school => school.id === formData.school)}
                        /> */}
                        <CustomMultiSelect
                            name="select-school" 
                            placeholder="Select School"
                            options={schools}
                            labelKey="name"
                            setSelectedOption={(option) => updateSelectedSchools(option)}
                            selectedOptions={selectedSchools}
                        />
                    </>
                }

                {user && user.id &&
                    <>
                        <label>Password:</label>
                        <a onClick={() => handlePasswordReset()} className="password-reset-btn">Send password reset</a>
                        {passwordResetSuccess && <p>Password reset email sent successfully!</p>}
                    </>
                }

                <div className="flex-row-buttons-cont">
                    <button onClick={() => {setView('list')}} className="grey-btn">Close</button>
                    <SaveButton
                        save={handleSubmit}
                        loading={loading}
                        textCreate="Add User"
                        textCreateLoading="Saving..."
                        textCreateSuccess="Saved"
                        textCreateError="Error"
                        textUpdate="Update User"
                        textUpdateLoading="Updating..."
                        textUpdateSuccess="Updated"
                        textUpdateError="Error"
                        isUpdating={!!user} 
                    />
                </div>

                {validationErrors.length > 0 &&
                    <div className="error-messages">
                        {validationErrors.map((error, index) => {
                            return <p key={index}>{error}</p>
                        })}
                    </div>
                }
            </form>
            
        </div>
    );
};

export default CrudUserSingle;
