import React, {useState, useEffect} from "react";
import { getDataResource } from "../../services/scpApi";
import Collapsible from "../collapsible/Collapsible";
import Bundle from "./Bundle";
import SaveButton from "../saveButton/SaveButton";



const BundleProgress = ({currentBundleProgress, updateBundleProgress, loading}) => {

    const [bundleData, setBundleData] = useState({});
    const [bundleDataReady, setBundleDataReady] = useState(false);
    // const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (!currentBundleProgress) {
            return;
        }

        (async () => {
            let bundles = await getDataResource('maths-bundles');
            bundles = combineProgressWithBundleData(bundles, currentBundleProgress);

            setBundleData(bundles);
            setBundleDataReady(true);
        })();
    }, [currentBundleProgress])


    /**
     * Combine the template bundle data (no progress), with the pupils progress bundle data
     * 
     * @param {*} bundles 
     * @param {*} currentBundleProgress 
     * @returns {object}
     */
    const combineProgressWithBundleData = (bundles, currentBundleProgress) => {

        Object.keys(currentBundleProgress).map((levelKey) => {
            Object.keys(currentBundleProgress[levelKey]).map((bundleKey) => {
                try {
                    bundles[levelKey][bundleKey]['completed'] = currentBundleProgress[levelKey][bundleKey]['completed'];
                } catch (error) {
                    console.log(error);
                }
            })
        })

        return bundles;
    }

    /**
     * Prepare bundlekey for display
     * 
     * @param {string} bundleKey 
     * @returns {string}
     */
    const convertBundleKeyToTitle = (bundleKey) => {
        let bundleTitle = bundleKey.replace(/-/g, ' ');
        return bundleTitle.charAt(0).toUpperCase() + bundleTitle.slice(1);
    }

    /**
     * Update the bundle state with new single bundle status change
     * 
     * @param {string} updatedLevelKey 
     * @param {string} updatedBundleKey 
     * @param {boolean} status 
     */
    const updateStatus = (updatedLevelKey, updatedBundleKey, status) => {

        let updatedBundleData = {...bundleData};

        updatedBundleData[updatedLevelKey][updatedBundleKey]['completed'] = status;

        setBundleData(updatedBundleData);
    }

    const handleUpdate = async () => {
        try {
            await updateBundleProgress(bundleData);
            // setSuccessMessage('Bundle progress updated successfully'); 
            // setTimeout(() => {
            //     setSuccessMessage('');
            // }, 3000);
        } catch (error) {
            console.error("Failed to update bundle progress:", error);
            // setSuccessMessage('Error updating bundle progress: ' + error.message); 
        }
    };

    return (
        <>
            {bundleDataReady && bundleData && bundleData['level-1'] ? (
                <>
                    <div className="bundle-progress-cont">
                        {Object.keys(bundleData).map((levelKey) => (
                            <div className="level-cont-outer">
                                <Collapsible title={convertBundleKeyToTitle(levelKey)} key={levelKey} open={true}>
                                    <div className="level-cont">
                                        {Object.keys(bundleData[levelKey]).map((bundleKey) => (
                                            <Bundle 
                                                levelKey={levelKey}
                                                bundleKey={bundleKey} 
                                                completed={bundleData[levelKey][bundleKey]['completed']} 
                                                label={bundleData[levelKey][bundleKey]['label']} 
                                                updateStatus={(levelKey, bundleKey, status) => updateStatus(levelKey, bundleKey, status)}
                                            />
                                        ))}
                                    </div>
                                </Collapsible>
                            </div>
                        ))}
                    </div>
                    <SaveButton
                        save={handleUpdate}
                        loading={loading}
                        textUpdate="Update Bundle Progress"
                        textUpdateLoading="Updating..."
                        textUpdateSuccess="Updated"
                        textUpdateError="Error"
                        isUpdating={!!currentBundleProgress}
                    />
                </>
            ) : (
                <p>Loading...</p>
            )}
        </>
    )
}

export default BundleProgress;