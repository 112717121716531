import React, {useState, useEffect} from "react";
import { getStudent, updatePupilPeriod } from "../../services/scpApi";
import SelectStudent from "../../features/selectStudent/SelectStudent";
import BundleProgress from "../../features/bundleProgress/BundleProgress";
import Notes from "../../features/notes/Notes";
// import { set } from "react-datepicker/dist/date_utils";


const UpdateStudentProgress = () => {

    const [selectedStudent, setSelectedStudent] = useState({});
    const [student, setStudent] = useState({});
    const [currentPeriod, setCurrentPeriod] = useState();
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {

        if (!selectedStudent.id) {
            setStudent({});
            setCurrentPeriod();
            return;
        }

        (async () => {
            try {
                const studentData = await getStudent(selectedStudent.id);
                setStudent(studentData);

            } catch (error) {
                console.log(error);
            }
        })();

    }, [selectedStudent])

    useEffect(() => {

        // get the last period for the pupil
        if (student.pupil_periods) {
            let lastPeriod = student.pupil_periods[student.pupil_periods.length - 1];
            setCurrentPeriod(lastPeriod);
        }

    }, [student])
 

    /**
     * Update bundle status server side
     * 
     * @param {object} newBundleProgress
     */
    const updateBundleStatus = (newBundleProgress) => {

        setError('');

        if (!student.id || !currentPeriod.tutor_group.id || !currentPeriod.year_group || !currentPeriod.pupil_status) { 
            console.log(student.id);
            console.log('missing data to update pupil period');
            return;
        }

        console.log('updating bundle progress');

        (async () => {
            setLoading('loading');
            try {
                let updatedPupilData = await updatePupilPeriod({
                    studentId: student.id,
                    tutorGroupId: currentPeriod.tutor_group.id,
                    yearGroup: currentPeriod.year_group,
                    pupilStatus: currentPeriod.pupil_status,
                    attendance: currentPeriod.attendance,
                    bundleProgress: newBundleProgress
                });
                setStudent(updatedPupilData);
                setLoading('success');
                setTimeout(() => {
                  setLoading(null);
                }, 3000);
            } catch (error) {
                console.log(error);
                setLoading('error');
                setError(error);
            }
        })();
    }

    const handleAddNote = (updatedNotes) => {
        
        setStudent((prevStudent) => ({
            ...prevStudent,
            notes: updatedNotes,
        }));
    };


    return (
        <div className="update-student-progress">
            <h1>Update Student Progress</h1>

            <SelectStudent setSelectedStudent={setSelectedStudent} selectedStudent={selectedStudent} />

            <div class="separator"></div>

            {student && currentPeriod &&
                <>
                    <div className="student-details-cont">
                        <h3>{student.name} {currentPeriod && ` - Year ${currentPeriod.year_group}`} {student.school && student.school.name && ` - ${student.school.name}`}</h3>
                        <p>{`${currentPeriod.period_name} ${currentPeriod.calendar_year}`}</p>
                    </div>

                    <p className="instruction">Click to update the bundle status (complete / in progress)</p>

                    <BundleProgress 
                        currentBundleProgress={currentPeriod.bundle_progress} 
                        updateBundleProgress={(newBundleProgress) => updateBundleStatus(newBundleProgress)} 
                        loading={loading} 
                    />

                    <div class="validation-errors">
                        {loading === 'success' && 'Bundle progress updated successfully'}
                        {loading === 'error' && 'Error updating bundle progress: ' + error.message}
                    </div>
                    
                    <Notes 
                        notes={student.notes} 
                        pupilId={student.id} 
                        onAddNote={handleAddNote}
                    />    
                </>
            }
        </div>
    );
}

export default UpdateStudentProgress;